import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import authService from "../../../services/authService";
import { RiLockPasswordFill } from "react-icons/ri";

export default function Login() {
  const navigate = useNavigate();
  const [codeSent, setCodeSent] = useState(false);

  const sendLogin = async (values) => {
    if (!values.username || values.username === "Select a user") {
      alert("Please select a user");
    } else {
      await authService.login(values.username, values.password).then(response => {
        setCodeSent(true);
      })
    }
  };

  const handleTextAlert = async (values) => {
    if (values.verificationCode.length !== 6) {
      alert("Please enter 6 digit verfication code sent to your mobile device");
    } else {
      //Validate 6 digit code
      await authService.verifyCode(values.username, values.verificationCode).then(verified => {
        if (verified.data) {
          navigate("/welcome");
        } else {
          alert("NOT VERIFIED");
        }
      })
    }
  };

  return (
    <section className="main-section">
      <h1>Login</h1>
      <section className="section-content">
        <Formik
          initialValues={{ username: "", verificationCode: "" }}
          onSubmit={(values) => handleTextAlert(values)}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Form className="auth-form" onSubmit={handleSubmit}>
              {!codeSent && (
                <>
                  <div>
                    <Form.Select
                      name="username"
                      aria-label="Default select example"
                      onChange={handleChange}
                    >
                      <option>Select a user</option>
                      <option value="matthew@homechoicenc.com">Matthew</option>
                      <option value="patrick@sembracare.com">Patrick</option>
                      <option value="jamie@sembracare.com">Jamie</option>
                      <option value="brandon@sembracare.com">Brandon</option>
                      <option value="elizabeth@sembracare.com">Lizzy</option>
                    </Form.Select>
                  </div>

                  <div>
                    <Form.Group className="mb-3 text-start" controlId="password">
                      <Form.Label>Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <RiLockPasswordFill />
                        </InputGroup.Text>
                        <Form.Control
                          type="password"
                          placeholder="Enter Password"
                          name="password"
                          onChange={handleChange}
                          value={values.password}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Button onClick={() => sendLogin(values)}>Login</Button>
                  </div>
                </>
              )}

              {codeSent && (<div className={codeSent ? "show" : "hide"}>
                <Form.Label htmlFor="verificationCode">
                  Verification Code
                </Form.Label>
                <Form.Control
                  type="text"
                  id="verificationCode"
                  onChange={handleChange}
                  minLength={6}
                  maxLength={6}
                />
                <Form.Text id="veriCodeHelpBlock">
                  Enter the 6 digit verification code sent to your mobile
                  device.
                </Form.Text>
                <br />
                <Button className="mt-4" type="button" onClick={(e) => handleTextAlert(values)}>
                  Submit
                </Button>
              </div>
              )}
            </Form>

          )}
        </Formik>
      </section>
    </section>
  );
}

// install json-server globally 
// 'npm install -g json-server'
// run the following cmd (to simulate api call)
// 'json-server -p 4000 -w src/mockData/employeeData.json'
// data is now live at 
// 'http://localhost:4000/employeeData'

// Link to react router turtorial 
// https://www.youtube.com/watch?v=OMQ2QARHPo0&list=PL4cUxeGkcC9iVKmtNuCeIswnQ97in2GGf&index=1

import React, { useState, useEffect } from "react";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import './App.css';
import RouteLayout from './components/common/routeLayout';
import Auth from './components/common/auth';
import Login from './components/pages/publicRoutes/login'
import Welcome from './components/pages/protectedPages/welcome';
import SendMoney from './components/pages/protectedPages/sendMoney';
import SelectEmployee from './components/pages/protectedPages/selectEmployee';
import Reports from './components/pages/protectedPages/reports';
import NotFound from './components/pages/publicRoutes/NotFound';
import EmployeeError from './components/common/employeeError';
import authService from "./services/authService";

function App() {
  const [userData, setUserData] = useState();

  useEffect(() => {
    const currUser = authService.getCurrentUser();
    setUserData(currUser);
  }, [])

const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/" element={<RouteLayout />}>
    <Route index element={<Login />} />
    <Route path="/welcome" element={<Auth><Welcome userData={userData} /></Auth>} />
    <Route path="/select-employee" element={<Auth><SelectEmployee /></Auth>}
      errorElement={<EmployeeError />}
    />
    <Route path="/send-money/:empID" element={<Auth><SendMoney /></Auth>} errorElement={<EmployeeError />} />
    <Route path="/reports" element={<Auth><Reports /></Auth>} />
    <Route path="*" element={<NotFound />} />
  </Route >
))


  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";

export default function Welcome({userData}) {
  const navigate = useNavigate();

  return (
    <section className="main-section">
      <h1>Welcome</h1>
      <section className="section-content text-center">
        {/* <p className="mb-3">Welcome to SembraCa$h, {userData.email}</p> */}
        <p className="mb-3">Welcome to SembraCa$h</p> 
        <div className="d-flex justify-content-around">
          <Button onClick={() => navigate("/select-employee")}>Send $</Button>
          <Button onClick={() => navigate("/reports")}>View Reports</Button>
        </div>
      </section>
    </section>
  );
}

import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router";

export default function Auth({ children }) {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    // using setTimeout to simulate async (auth) event
    setTimeout(() => {
      if (localStorage.getItem("userJwtToken")) {
        setCurrentUser({ id: "1", name: "foo" });
      } else {
        setCurrentUser(null);
      }
    }, 300);
  }, []);

  if (currentUser === undefined) {
    return null;
  }

  if (!currentUser) {
    // return <Navigate to="/" />;
    navigate("/");
  }

  return children;
}

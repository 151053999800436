export default function Reports() {
  return (
    <main className="main-section">
      <h1>Reports</h1>
      <section className="section-content">
        <p>Here is some reports page content</p>
      </section>
    </main>
  );
}

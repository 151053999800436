import { useMemo, useEffect } from "react";
// Link to useTable tutorial
// https://www.youtube.com/watch?v=YwP4NAZGskg&list=PLC3y8-rFHvwgWTSrDiwmUsl4ZvipOw9Cz
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { Table } from "react-bootstrap";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import { VscSmiley } from "react-icons/vsc";
import { GlobalFilter } from "./GlobalFilter";

export default function SelectEmployeeTable({ employeeList }) {
  const navigate = useNavigate();

  // useEffect(() => {
  //   // using setTimeout to simulate async (auth) event
  //   console.log(rows)
  // }, employeeList);

  const COLUMNS = [
    {
      Header: "First Name",
      accessor: "empFirstName",
    },
    {
      Header: "Last Name",
      accessor: "empLastName",
    },
    {
      Header: "IVR ID",
      accessor: "empIVRID",
    },
    {
      Header: "Phone",
      accessor: "empPhoneNumber",
    },
    {
      Header: "Hours Worked",
      accessor: "hoursWorked"
    },
    {
      Header: "EmployeeID",
      accessor: "empID"      
    }
  ];

  const colData = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => employeeList, []);
  const data = employeeList;
  const initialState = {hiddenColumns:['empID']};

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns: colData,
      data: data,
      initialState
    },
    useGlobalFilter,
    useSortBy
  );

  const { globalFilter } = state;

  const handleRowClick = (selectedEmployee) => {
    navigate(`/send-money/${selectedEmployee.values.empID}`);
  };

  return (
    <>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      <Table striped bordered hover {...getTableProps}>
        <thead>
          {headerGroups.map((group, index) => (
            <tr key={index} {...group.getHeaderGroupProps()}>
              {group.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <AiFillCaretDown className="ms-2" />
                      ) : (
                        <AiFillCaretUp className="ms-2" />
                      )
                    ) : (
                      <VscSmiley className="ms-2" />
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
          {/* {headerGroups.map((group, index) => {
              console.log(group);
            })} */}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => handleRowClick(row)}
                className="clickable-row"
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
          {/* {rows.map((row) => {
              console.log(row);
            })} */}
        </tbody>
      </Table>
    </>
  );
}

import http from "./httpService";

export async function getEmployees() {
    const getEmployeesURL = process.env.REACT_APP_SEMBRA_URL + "/sembraCash/getEmployees";

    return await http.get(getEmployeesURL).catch((err) => {console.log(err)})
}

export async function getCashEmployee(empID) {
    const getCashEmployeeURL = process.env.REACT_APP_SEMBRA_URL + "/sembraCash/getCashEmployee";

    return await http.post(getCashEmployeeURL, {empID:empID}).catch((err) => {console.log(err)})
}

export async function sendDOTSPayout(payoutObject) {
    const getCashEmployeeURL = process.env.REACT_APP_SEMBRA_URL + "/sembraCash/sendPayout";

    return await http.post(getCashEmployeeURL, payoutObject).catch((err) => {console.log(err)})
}

export default {
    getEmployees,
    getCashEmployee,
    sendDOTSPayout
}
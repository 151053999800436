import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/sembraCashLogo.png";
import { HiHome, HiLogout, HiUserCircle } from "react-icons/hi";
import authService from "../../services/authService";

export default function Header() {
  // *************
  // TO DO

  // ADD Logout Click Event
  // Add User Menu - What do we want here?
  // *************

  const navigate = useNavigate();
  return (
    <header>
      <img src={Logo} alt="SembraCash Logo" />
      <div className="user-menu">
        <HiHome onClick={() => navigate("/welcome")} />
        <HiLogout onClick={() => authService.logout() } />
        {/* <HiUserCircle onClick={() => alert("Functionality Coming Soon")} /> */}
      </div>
    </header>
  );
}

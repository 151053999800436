import SelectEmployeeTable from "../../common/tables/selectEmployeeTable";
import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { getEmployees } from "../../../services/employeeService";

export default function SelectEmployee() {
  const [employeeList, setEmployeeList] = useState([]);

  useEffect(() => {
    // using setTimeout to simulate async (auth) event
    getEmployees().then(res => {
      const employeePull = res.data;
      setEmployeeList(employeePull);
    })

  }, []);

  return (
    <section className="main-section">
      <h1>Select Employee Page </h1>
      <section className="section-content">
        <SelectEmployeeTable employeeList={employeeList} />
      </section>
    </section>
  );
}

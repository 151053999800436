import { useEffect, useState } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { getCashEmployee, sendDOTSPayout } from "../../../services/employeeService";

export default function SendMoney() {
  const [employee, setEmployee] = useState({});
  const [showConfirm, setShowConfirm] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  let { empID } = useParams();

  useEffect(() => {
    // using setTimeout to simulate async (auth) event
    getCashEmployee(empID).then(res => {
      const employeePull = res.data;
      setEmployee(employeePull);
    })

  }, []);

  const handleNext = (values) => {
    if (!values.workloadID || !values.cashAmount) {
      alert("Please include WorkLoadID and Cash Amount");
    } else {
      setShowConfirm(true);
      setConfirmationMessage(
        `Submitting ${values.cashAmount} to ${employee.empFirstName}, ${employee.empLastName}`
      );
    }
  };

  const handleCashSubmit = async (values) => {
    // console.log(
    //   `Submitting ${values.cashAmount} to ${employee.firstName}, ${employee.lastName}`,
    //   values
    // );
    // console.log(employee)

    let payoutObject = {
      empID: employee.empID,
      message: "SembraCare Payout For Home Care Fill In",
      amount: parseFloat(values.cashAmount)*100,
      phoneNumber: employee.empPhoneNumber.replace(/\D/g, '')
    }

    await sendDOTSPayout(payoutObject).catch((err) => console.log(err));

    let logRecord = {
      sembraSynced: true,
      dotsId: "111111",
      cashAmount: values.cashAmount,
      employeeIVRID: employee.empIBRID,
      employeeId: employee.empID,
      date: "date",
      agency: "HCH"
    };

    // Send log record to db
    console.log(logRecord);
  };

  return (
    <section className="main-section">
      <h1 className="text-center">Send Money</h1>
      <section className="section-content">
        <h2>Employee Information</h2>
        <div className="d-flex justify-content-around">
          <div>
            {Object.keys(employee).map((key, index) => (
              index !== 0 && index !== 6 && <p key={key}>
                <strong>{key} </strong>
              </p>
            ))}
          </div>
          <div>
            {Object.values(employee).map((value, index) => (
              index !== 0 && index !== 6 && <p key={value}>{value}</p>
            ))}
          </div>
        </div>
      </section>
      <section className="section-content">
        <Formik
          initialValues={{ workloadID: "", cashAmount: "" }}
          onSubmit={(values) => handleCashSubmit(values)}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Form className="send-money-form" onSubmit={handleSubmit}>
              {/* {console.log("is Submiiting", isSubmitting)} */}
              {!showConfirm ? (
                <div className={showConfirm ? "hide" : "show"}>
                  <Form.Group className="text-start mb-3">
                    <Form.Label htmlFor="workloadID">Workload ID</Form.Label>
                    <Form.Control
                      type="text"
                      id="workloadID"
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="text-start">
                    <Form.Label htmlFor="cashAmount">Cash Amount</Form.Label>
                    <Form.Control
                      type="text"
                      id="cashAmount"
                      onChange={handleChange}
                      minLength={4}
                      maxLength={5}
                    />
                  </Form.Group>
                  <Button className="mt-4" onClick={() => handleNext(values)}>
                    Next
                  </Button>
                </div>
              ) : (
                <div className={showConfirm ? "show" : "hide"}>
                  <p>{confirmationMessage}</p>
                  <Button
                    className="mt-4"
                    type="submit"
                  >
                    Confirm
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </section>
    </section>
  );
}

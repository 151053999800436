import http from "./httpService";
import jwtDecode from "jwt-decode";

export function login(username, password) {
    const loginURL = process.env.REACT_APP_COGNITO_URL + "/aws-auth/login";
    const cognitoUser = {
        Username: username,
        Password: password,
        realm: "SembraCash"
    }
    try {
        return http.post(loginURL, cognitoUser).then(async res => {
            if (res.message === "User is not confirmed." || res.message === "Incorrect username or password." || res.message === "User Realm not found") {
                console.log("ERROR")
                return res.message;
            } else {
                localStorage.setItem('user', JSON.stringify(res.data));
                localStorage.setItem('userJwtToken', res.data.idToken);
                localStorage.setItem('userAccessToken', res.data.accessToken.jwtToken);
                localStorage.setItem('userRefreshToken', res.data.refreshToken.token);

                const currentUser = getCurrentUser();
                localStorage.setItem('userEmail', currentUser.email);
                return currentUser.phone_number;
            }
        }).then(async phoneNumber => {
            const newMessage = {
                messageUser: username,
                messageFrom: "SembraCash Verification",
                messageTo: phoneNumber
            }
            const send2FAURL = process.env.REACT_APP_SEMBRA_URL + "/bandwidth/2FA"
            return await http.post(send2FAURL, newMessage).then(returnData => {
                const saveVeriCode = process.env.REACT_APP_SEMBRA_URL + "/sembraCash/saveUser";
                const userSave = {
                    username: username,
                    veriCode: returnData.data.veriCode
                }
                return http.post(saveVeriCode, userSave);
            })
        })
    }
    catch {
        return "Error in Sending";
    };
}

export function verifyCode(username, veriCode) {
    const checkVeriCodeURL = process.env.REACT_APP_SEMBRA_URL + "/sembraCash/verifyUser"
    const veriCheck = {
        username: username,
        veriCode: veriCode
    }
    return http.post(checkVeriCodeURL, veriCheck);
}

export function renewToken() {
    const renewURL = process.env.REACT_APP_COGNITO_URL + "/aws-auth/renewToken";

    const cognitoUser = {
        Username: localStorage.getItem('userEmail'),
        Refreshtoken: localStorage.getItem('userRefreshToken')
    }

    return http.post(renewURL, cognitoUser).then(async res => {
        localStorage.setItem('userJwtToken', res.id_token);
        localStorage.setItem('userAccessToken', res.access_token);
        localStorage.setItem('userRefreshToken', res.refresh_token);
        return res.id_token;
    }).catch(err => {
        return err;
    })
}

export function logout() {
    localStorage.clear();
    window.location = "/"
}

export function getCurrentUser() {
    try {
        const jwt = localStorage.getItem("userJwtToken");
        return jwtDecode(jwt);
    } catch (ex) {
        return null;
    }
}

export function getJwt() {
    return localStorage.getItem("userJwtToken");
}

export default {
    login,
    verifyCode,
    renewToken,
    logout,
    getCurrentUser,
    getJwt
}
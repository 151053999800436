import { Link, useRouteError } from "react-router-dom";

export default function EmployeeError() {
  const error = useRouteError();
  return (
    <section className="main-section error">
      <h1>Error</h1>
      <section className="section-content">
        <p>{error.message}</p>
        <Link to="/welcome">Back to homepage</Link>
      </section>
    </section>
  );
}
